import * as React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FlexibleContentWrapper from "../components/flexible-content-wrapper"
import Hero from "../components/hero"

const IndexPage = ({ data }) => {
  const { title, hero, flexibleContent } =
    data.craftCaseStudiesIndexCaseStudiesIndexEntry

  return (
    <Layout>
      <Seo title={title} />

      <Hero
        title={title}
        backgroundColour={hero[0].backgroundColour}
        textColour={hero[0].textColour}
        hideTitle={hero[0].hideTitle}
      />

      <FlexibleContentWrapper flexibleContent={flexibleContent} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query WorkPageQuery {
    craftCaseStudiesIndexCaseStudiesIndexEntry {
      title
      hero {
        ... on Craft_hero_hero_BlockType {
          backgroundColour
          textColour
          hideTitle
        }
      }
      flexibleContent {
        ...flexibleContentFragment
      }
    }
  }
`
